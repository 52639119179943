<template>
  <div>
 
    <!-- Table Families Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
      
      </div>

      <b-table
        ref="refFamiliesListTable"
        class="position-relative"
        :items="fetchFamiliesBenef"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
     
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>
            <!-- Column: Role -->
        <template #cell(barecode)="data">
          <div class="text-nowrap">
            
            <span class="align-text-top text-capitalize">{{ data.item.barecode }}</span>
          </div>
        </template>


        <!-- Column: Actions -->
         <!-- <template #cell(actions)="data">
         
            <b-dropdown-item :to="{ name: 'edit-beneficiary', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>

           </template> -->
         
      </b-table>
     
    </b-card>
  
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {  watch } from "@vue/composition-api";

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import router from '@/router'
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";

import familyStoreModule from "@/views/apps/families/familyStoreModule";
export default {
  setup(props) {
    const FAMILY_APP_STORE_MODULE_NAME = "app-family";

    // Register module
    if (!store.hasModule(FAMILY_APP_STORE_MODULE_NAME))
      store.registerModule(FAMILY_APP_STORE_MODULE_NAME, familyStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FAMILY_APP_STORE_MODULE_NAME))
        store.unregisterModule(FAMILY_APP_STORE_MODULE_NAME);
    });

  const refFamiliesListTable = ref(null);
const fetchFamiliesBenef = (ctx, callback) => {
 
    store
      .dispatch("app-family/fetchFamiliesBenef", { id:props.id })
       
      .then((response) => {
  // console.log(response)
  
        const { data, total } = response.data.data;
    
        callback(data);
     

     
      })
      
  };
   watch(() => props.id,() => {
    refetchData();
  });
   const refetchData = () => {
    refFamiliesListTable.value.refresh();
  };
  const tableColumns = [
    { key: "first_name", label: "الاسم", sortable: true },
    {
      key: "father_name",
      label: "  اسم الأب",
      
      sortable: true,
    },
    {
      key: "last_name",
      label: "النسبة",
      
    },
    
    {
      key: "mother_name",
      label: " اسم الأم",
      
      sortable: true,
    },
 

  ]
  

    return {
      // Sidebar
   
      fetchFamiliesBenef,

      tableColumns,
     
      
     
    
    
      refFamiliesListTable,
      refetchData,

    //   // Filter
    //   avatarText,

     
    };
  },

  components: {


    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BCardCode
  },

  props: {
  id: {
    type: Number,
   
  },
}
};
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}

</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>